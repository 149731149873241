<template>
  <ys-dialog
      :visible.sync="isVisible"
      title="选择食谱"
  >
    <div slot="title" class="padding-horizontal-large padding-vertical layout-horizontal layout-middle">
      <div class="font-weight-bold">{{title}}</div>
      <div class="layout-flexible"></div>
      <ys-input
          class="input-search"
          prefix-icon="el-icon-search"
          placeholder="搜索食谱"
          :maxlength="20"
          v-model="search"
      ></ys-input>
    </div>
    <ys-infinite-scroll-view class="scrollbar" @loadmore="loadMore">
      <div class="cookery-book-list padding">
        <div
            class="list-item layout-horizontal layout-middle padding-horizontal-large padding-vertical font-size-medium"
            v-for="cookeryBook in cookeryBookList"
            :key="cookeryBook.id"
            @click="clickCookeryBook(cookeryBook)"
        >
          <span class="fas fa-utensils"></span>
          <div class="margin-left layout-flexible" style="overflow: hidden;">
            <div>{{ cookeryBook.name }}</div>
            <div v-if="cookeryBook.message"
                 class="font-size-small font-color-placeholder font-nowrap"
            >
              {{ cookeryBook.message }}
            </div>
          </div>
        </div>
      </div>
    </ys-infinite-scroll-view>
    <div slot="footer">
      <ys-button
          type="secondary"
          lighting
          @click="clickClose"
      >关闭
      </ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import YsInput from "@/components/wedigets/YsInput";
import YsButton from "@/components/wedigets/YsButton";

export default {
  name: "PublicCookeryBookPickerDialog",
  mixins: [httpapi],
  components: {YsButton, YsInput, YsInfiniteScrollView, YsDialog},
  props: {
    visible: Boolean,
    organizationId: Number,
    title: {
      type: String,
      default: '选择食谱',
    }
  },
  data() {
    return {
      /**
       * 显示弹窗
       */
      isVisible: false,

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 搜索条件
       */
      search: null,

      /**
       * 食谱列表
       */
      cookeryBookList: [],
      pageNum: 1,
      totalPages: 0,
      pageSize: 20,
    }
  },
  watch: {
    visible() {
      this.loadingCode++;
      this.cookeryBookList = [];
      this.pageNum = 1;
      this.totalPages = 0;
      this.isVisible = this.visible;
      if (this.organizationId) {
        this.loadCookeryBookList();
      }
    }
  },
  methods: {
    clickClose() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    clickCookeryBook(cookeryBook) {
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('pick', [cookeryBook])
    },
    isBusy() {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadCookeryBookList(pageNum = 1) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/cookery_book/list/${this.organizationId}/${pageNum}/${this.pageSize}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let cookeryBookList = pageNum === 1 ? [] : this.cookeryBookList;
            this.$appendAfter(cookeryBookList, res.data.list);
            this.pageNum = res.data.pageNum;
            this.totalPages = res.data.pages;
            this.cookeryBookList = cookeryBookList;
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadCookeryBookList(this.pageNum + 1);
      }
    }
  }
}
</script>

<style scoped>

.scrollbar {
  height: 60vh;
}

.input-search {
  text-align: left;
  width: 300px;
}

.list-item {
  width: unset;
  min-width: unset;
}

</style>