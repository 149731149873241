<template>
  <div class="page layout-horizontal padding">
    <div
        class="layout-vertical"
        style="height: 100%;">
      <div class="layout-inflexible padding">
        <ys-button @click="clickAddCookerBook">+ 添加食谱</ys-button>
      </div>
      <div class="layout-flexible"
           style="overflow: hidden;">
        <ys-infinite-scroll-view
            style="height: 100%;"
        >
          <div
              class="list-item layout-horizontal layout-middle font-size-medium padding-horizontal-large padding-vertical"
              v-for="cookeryBook in cookeryBookList"
              :key="cookeryBook.id"
              @click="clickCookeryBook(cookeryBook)"
          >
            <span class="fas fa-utensils"></span>
            <div class="margin-left layout-flexible" style="overflow: hidden">
              <div
                  :class="{
                     'is-stopped': cookeryBook.state === BusinessState.STOPPED
                  }"
                  class="font-size-medium"
              >
                <span v-if="cookeryBook.state === BusinessState.STOPPED">[已停止]</span>
                <span>{{ cookeryBook.name }}</span>
              </div>
              <div class="font-color-secondary font-nowrap font-size-small">
                <span v-if="cookeryBook.state === BusinessState.STARTED" class="margin-right">开始日期:{{cookeryBook.startDate}}</span>
                <span v-if="cookeryBook.message">
                {{ cookeryBook.message }}
                </span>
              </div>
            </div>
            <div class="layout-inflexible">
              <ys-dropdown>
                <ys-button round lighting type="secondary"
                           @click.stop="()=>{}">
                  <span class="fas fa-ellipsis-h"></span>
                </ys-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      v-if="cookeryBook.state === BusinessState.STARTED"
                      @click.native="clickStopCookeryBook(cookeryBook)"
                  ><i class="fas fa-stop"></i> 停止
                  </el-dropdown-item>
                  <el-dropdown-item
                      v-else
                      @click.native="clickStartCookeryBook(cookeryBook)"
                  ><i class="fas fa-play"></i> 开始
                  </el-dropdown-item>
                  <el-dropdown-item
                      class="font-color-danger"
                      @click.native="clickDeleteCookeryBook(cookeryBook)"
                  ><i class="fas fa-trash-alt"></i> 删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </ys-dropdown>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>
    </div>
    <cookery-book-type-dialog
        :visible.sync="cookeryBookTypeDialogVisible"
        @pick="handleCookeryBookTypePicked"
    ></cookery-book-type-dialog>
    <public-cookery-book-picker-dialog
        :organization-id="organizationId"
        :visible.sync="cookeryBookPickerDialogVisible"
        @pick="handleCookeryBookPicked"
    ></public-cookery-book-picker-dialog>
    <delete-dialog
        :visible.sync="deleteRemindDialogVisible"
        title="删除食谱"
        message="您确定要删除该食谱吗？"
        @confirm="confirmDeleteCookeryBook"
    ></delete-dialog>
  </div>
</template>

<script>
import YsButton from "@/components/wedigets/YsButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import PublicCookeryBookPickerDialog from "@/components/dialog/PublicCookeryBookPickerDialog";
import CookeryBookTypeDialog from "@/pages/user/cookerybook/CookeryBookTypeDialog";
import BusinessState from "@/assets/javascript/business-state";
import {TimeUtils} from "@/assets/javascript/time-utils";
import YsDropdown from "@/components/wedigets/YsDropdown";
import DeleteDialog from "@/components/dialog/DeleteDialog";

export default {
  name: "CookeryBook",
  mixins: [httpapi],
  components: {
    DeleteDialog,
    YsDropdown, CookeryBookTypeDialog, PublicCookeryBookPickerDialog, YsInfiniteScrollView, YsButton
  },
  props: {
    organizationId: Number,
    userId: Number,
  },
  data() {
    return {
      /**
       * 常量
       */
      BusinessState,

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 发送食谱
       */
      cookeryBookPickerDialogVisible: false,
      cookeryBookTypeDialogVisible: false,

      /**
       * 食谱列表
       */
      cookeryBookList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,

      /**
       * 删除食谱
       */
      deletingCookeryBook: null,
      deleteRemindDialogVisible: false,
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;

        this.cookeryBookList = [];
        this.pageNum = 1;
        this.totalPages = 0;
        if (this.organizationId && this.userId) {
          this.loadCookeryBookList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    clickAddCookerBook() {
      this.cookeryBookTypeDialogVisible = true;
    },
    clickCookeryBook(cookeryBook) {
      this.$router.push({
        name: 'user_cookery_book',
        query: {
          id: cookeryBook.id,
        }
      });
    },
    clickStartCookeryBook(cookeryBook) {
      this.startCookeryBook(cookeryBook.id);
    },
    clickStopCookeryBook(cookeryBook) {
      this.stopCookeryBook(cookeryBook.id);
    },
    clickDeleteCookeryBook(cookeryBook) {
      this.deletingCookeryBook = cookeryBook;
      this.deleteRemindDialogVisible = true;
    },
    confirmDeleteCookeryBook() {
      this.removeCookeryBook(this.deletingCookeryBook.id);
      this.deletingCookeryBook = null;
    },
    handleCookeryBookTypePicked(type) {
      switch (type) {
        case 'public':
          this.cookeryBookPickerDialogVisible = true;
          break;
        case 'private':
          this.$router.push({
            name: 'user_cookery_book',
          });
          break;
      }
    },
    handleCookeryBookPicked(cookeryBookList) {
      this.sendCookeryBookToUser(cookeryBookList[0].id);
    },
    isBusy() {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadCookeryBookList(pageNum = 1) {
      if (this.isBusy()) return;
      this.$reqGet({
        path: `/user_cookery_book/list/${this.userId}/${this.organizationId}/${pageNum}/${this.pageSize}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let cookeryBookList = pageNum === 1 ? [] : this.cookeryBookList;
            this.$appendAfter(cookeryBookList, res.data.list);
            this.pageNum = pageNum;
            this.totalPages = res.data.pages;
            this.cookeryBookList = cookeryBookList;
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadCookeryBookList(this.pageNum + 1);
      }
    },
    loadCookeryBook(id) {
      this.$reqGet({
        path: `/cookery_book/${id}`,
      })
          .returnWhenCodeMatches()
          .then(res => {
            let cookeryBook = res.data;
            if (cookeryBook.organizationId === this.organizationId && cookeryBook.userId === this.userId) {
              this.$appendBefore(this.cookeryBookList, [cookeryBook]);
            }
          })
          .catch(() => this.$message.error('加载失败'));
    },
    sendCookeryBookToUser(cookeryBookId) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPost({
        path: `/user_cookery_book/public/${this.userId}/${this.organizationId}/${cookeryBookId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            for (let cookeryBook of this.cookeryBookList) {
              if (cookeryBook.state === BusinessState.STARTED) {
                cookeryBook.state = BusinessState.STOPPED;
                cookeryBook.stopDate = TimeUtils.format('yyyy-MM-dd', new Date());
              }
            }
            this.$appendBefore(this.cookeryBookList, [res.data]);
          })
          .catch(() => this.$message.error('操作失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    startCookeryBook(cookeryBookId) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPatch({
        path: `/user_cookery_book/${cookeryBookId}/state/start`,
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.$message.success('食谱已开始')
            let cookeryBook = res.data;
            if (cookeryBook.organizationId === this.organizationId && cookeryBook.userId === this.userId) {
              for (let tmp of this.cookeryBookList) {
                if (tmp.state === BusinessState.STARTED) {
                  tmp.state = BusinessState.STOPPED;
                  tmp.endDate = TimeUtils.format('yyyy-MM-dd', new Date());
                }
              }
              this.$appendBefore(this.cookeryBookList, [cookeryBook]);
            }
          })
          .catch(() => this.$message.error('操作失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    stopCookeryBook(cookeryBookId) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPatch({
        path: `/user_cookery_book/${cookeryBookId}/state/stop`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.$message.success('食谱已开始');
            let cookeryBook = res.data;
            if (cookeryBook.organizationId === this.organizationId && cookeryBook.userId === this.userId) {
              this.$appendBefore(this.cookeryBookList, [cookeryBook]);
            }
          })
          .catch(() => this.$message.error('操作失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    removeCookeryBook(cookeryBookId) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqDelete({
        path: `/cookery_book/${cookeryBookId}`,
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.$message.success('删除成功');
            for (let n = this.cookeryBookList.length - 1; n >= 0; n--) {
              if (this.cookeryBookList[n].id === cookeryBookId) {
                this.cookeryBookList.splice(n, 1);
                break;
              }
            }
          })
          .catch(() => this.$message.error('删除失败'))
          .complete(() => this.busyLoadingCode = 0);
    }
  }
}
</script>

<style scoped>

.page {
  width: 100%;
}

.is-stopped {
  color: #bec0c0;
  text-decoration: line-through;
}

</style>