<template>
  <ys-dialog
      size="small"
      :visible.sync="isVisible"
      @update:visible="visible=>$emit('update:visible', visible)"
      title="添加食谱"
  >
    <div class="layout-horizontal layout-center">
      <div class="cookery-book-type layout-vertical layout-center layout-middle"
        @click="clickCookeryBookType('private')">
        <div>
          <span class="icon fas fa-utensils font-color-success"></span>
        </div>
        <div class="margin-top">
          个性化食谱
        </div>
      </div>
      <div class="cookery-book-type layout-vertical layout-center layout-middle"
        @click="clickCookeryBookType('public')">
        <div>
          <span class="icon fas fa-hamburger font-color-warning"></span>
        </div>
        <div class="margin-top">
          公共食谱
        </div>
      </div>
    </div>

  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";

export default {
  name: "CookeryBookTypeDialog",
  components: {YsDialog},
  props: {
    visible: Boolean,
  },
  data() {
    return {
      /**
       * 显示弹窗
       */
      isVisible: false,
    }
  },
  watch: {
    visible() {
      this.isVisible = this.visible;
    }
  },
  methods: {
    clickCookeryBookType(type) {
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('pick', type);
    }
  }
}
</script>

<style scoped>

.cookery-book-type {
  width: 88px;
  height: 88px;
  border-radius: 8px;
  border: 1px solid #dadada;
  background-color: white;
  margin: 10px;
  cursor: pointer;
  padding: 10px;
}

.cookery-book-type:hover {
  background-color: #f1f1f1;
}

.cookery-book-type .icon {
  font-size: 32px;
}
</style>